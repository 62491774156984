import React from 'react';

const Contact = () => {
  return (
    <div className="flex mt-32 mb-10   ">
      <div className="bg-gray-800 w-[450px] flex flex-col items-center justify-center h-60 text-white rounded-xl p-6  text-center shadow-lg">
        <h2 className="text-3xl font-kanit font-bold mb-2">
          Contact<span className="text-indigo-400 font-extrabold"> .</span>
        </h2>
        <p className="text-gray-400 text-sm mb-4 ">
          Shoot me an email if you want to connect! You can also find me on{' '}
          <a
            href="https://www.linkedin.com/in/aymansalimk/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-400 hover:underline"
          >
            Linkedin
          </a>{' '}
          or{' '}
          <a
            href="https://x.com/aymansalim123"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-400 hover:underline"
          >
            Twitter
          </a>{' '}
          if that's more your speed.
        </p>
        <a href="mailto:aymansalimkunnath@gmail.com" className="block">
          <div className="flex items-center justify-center bg-gray-700 text-white py-2 px-4 rounded-lg shadow-md hover:bg-indigo-400 transition duration-300 ease-in-out">
            <span className="mr-2">📧</span> aymansalimkunnath@gmail.com
          </div>
        </a>
      </div>
    </div>
  );
};

export default Contact;
