import React from 'react'

const Skills = () => {
  return (
    <div className='w-full sm:w-2/3 mt-16 mb-16 px-4 sm:mt-32 sm:mb-32'>
      <div className='flex flex-col sm:flex-row items-center'>
        <span className='dark:text-white font-kanit text-4xl sm:text-6xl font-bold'>
          Experience<span className='text-blue-500 font-serif'>.</span>
        </span>
        <div className='hidden sm:block sm:w-full h-[1px] bg-slate-400 dark:bg-white ml-4'></div>
      </div>

      <div className='dark:text-white mt-10 flex flex-col gap-3'>
        <div className='flex flex-col sm:flex-row justify-between'>
          <span className='font-extrabold text-lg sm:text-xl font-kanit'>
            <a href='https://gingr.in/'>Gingr Informatics</a>
          </span>
          <span className='font-semibold text-md sm:text-lg'>
            May'24 - Present
          </span>
        </div>
        <div className='flex flex-col sm:flex-row justify-between'>
          <span className='text-blue-500 font-kanit font-bold text-md sm:text-lg'>
            Front-End Developer Intern
          </span>
          <span className='font-semibold text-md sm:text-lg'>Chennai</span>
        </div>
        <div>
          <p className='font-kanit text-sm sm:text-base'>
            I spearheaded the development of a responsive landing page using
            HTML, Tailwind CSS, and JavaScript. Boosted user engagement with
            integrated animations and ensured seamless performance across all
            devices. Worked closely with the design team to align with UI/UX
            best practices, iterating quickly in a fast-paced startup
            environment.
          </p>
        </div>
        <div className='flex flex-wrap gap-3'>
          <span className='border-2 px-3 py-1 dark:bg-slate-700 rounded-md hover:cursor-pointer hover:scale-110 duration-500  font-kanit bg-violet-500 hover:bg-violet-600'>
            HTML
          </span>
          <span className='border-2 px-3 py-1 dark:bg-slate-700 rounded-md hover:cursor-pointer hover:scale-110 duration-500  font-kanit bg-violet-500 hover:bg-violet-600'>
            CSS
          </span>
          <span className='border-2 px-3 py-1 dark:bg-slate-700 rounded-md hover:cursor-pointer hover:scale-110 duration-500  font-kanit bg-violet-500 hover:bg-violet-600'>
            Tailwind
          </span>
          <span className='border-2 px-3 py-1 dark:bg-slate-700 rounded-md hover:cursor-pointer hover:scale-110 duration-500 font-kanit bg-violet-500 hover:bg-violet-600'>
            JavaScript
          </span>
        </div>
      </div>
    </div>
  )
}

export default Skills
